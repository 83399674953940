/*//////////////////////////////////
    DESKTOP CSS MQ: 769px and up 
//////////////////////////////////*/

@media screen and (min-width: 769px) {
/*//////////////////////////////////
     MAIN CSS
//////////////////////////////////*/
:root {
  --backgroundDarkGray: #2b2b2b;
  --lightGrayText: #bdbdbd;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 22px;
  font-family: 'Amatic SC', cursive;
}

body {
  min-height: 100vh;
  background-color: #bdbdbd;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  background-color: var(--lightGrayText);
}

/*//////////////////////////////////
     HEADER CSS
//////////////////////////////////*/
header {
  width: 100%;
  padding: 0 0.25em;
  background-color: var(--backgroundDarkGray);
  color: var(--lightGrayText);
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}


#nav {
  padding: 15px;
  height: 100%;
}

.nav_btn {
  font-family: 'Amatic SC', cursive;
  font-size: x-large;
  line-height: 87%;
  text-align: center;
  padding: 5px;
  margin-left: 1px;
  margin-right: 1px;
  color: white;
}

#me_nav_btn {
  border: 0;
}

#me_nav_btn:hover {
  
}

#projects_nav_btn {
  border: 0;
  height: 100%;
}

#find_me_nav_btn {
  border: 0;
  height: 100%;
}

#contact_nav_btn {
  border: 0;
  height: 100%;
}

#nav_wrap {
  height: 100%;
}

/*//////////////////////////////////
     FOOTER CSS
//////////////////////////////////*/
footer {
  width: 100%;
  padding: 0.25em;
  background-color: var(--backgroundDarkGray);
  color: var(--lightGrayText);
  text-align: center;
  position: fixed;
  bottom: 0;
}

/*//////////////////////////////////
     POPUP CSS
//////////////////////////////////*/
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  position: relative;
  padding: 32px;
  width: 100%;
  max-width: 800px;
  background-color: var(--lightGrayText);
  text-align: center;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;

  font-size: 20px;
  font-family: inherit;
  padding: 5px 12px;

  margin: 0;
  padding: 0;
  border-width: 0;
  border-color: transparent;
  background: transparent;
  font-weight: 400;
  cursor: pointer;
  font-size: 20px;
  font-family: inherit;
  padding: 5px 12px;
  overflow: hidden;
  border-width: 0;
  border-radius: 4px;
  background: rgba(255,255,255,0.4);
  color: #000;
  -webkit-transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.popup-inner .close-btn:hover {
  opacity: 1.75;
}

/*//////////////////////////////////
     BLACKJACK CSS
//////////////////////////////////*/
.game {
  width: 100%;
  /*height: 100%;*/
  font-family: inherit;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
}

.game_score {
  position: absolute;
  margin-left: 10px;
  font-size: 30px;
}

.game_controls {
  /*position: absolute;*/
  padding: 5px;
  font-family: inherit;
}

.control_btn {
  padding: 5px;
  font-family: inherit;
  font-size: 20px;
}

.divider {
  width: 5px;
  height: auto;
  display: inline-block;
}

.dealer_cards {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 1;
  margin-bottom: 30px;
}

.player_cards {
  grid-column-start: 2;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 2;
}

#dlr_crd_img_1, #dlr_crd_img_2, #dlr_crd_img_3, 
  #dlr_crd_img_4, #dlr_crd_img_5 {
  margin: 2px;
  width: 185px;
  height: auto;
}

#plyr_crd_img_1, #plyr_crd_img_2, #plyr_crd_img_3
  #plyr_crd_img_4, #plyr_crd_img_5 {
  margin: 2px;
  width: 185px;
  height: auto;
}

.game_controls {
  grid-column-start: 6;
  grid-column-end: 6;
  grid-row-start: 3;
  grid-row-end: 3;
}
}














/*//////////////////////////////////
    TABLET CSS MQ: 769px and up 
//////////////////////////////////*/

@media screen and (min-width: 481px) and (max-width: 768px) {
  /*//////////////////////////////////
       MAIN CSS
  //////////////////////////////////*/
  :root {
    --backgroundDarkGray: #2b2b2b;
    --lightGrayText: #bdbdbd;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    font-size: 22px;
    font-family: 'Amatic SC', cursive;
  }
  
  body {
    min-height: 100vh;
    background-color: #bdbdbd;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    background-color: var(--lightGrayText);
  }
  
  /*//////////////////////////////////
       HEADER CSS
  //////////////////////////////////*/
  header {
    width: 100%;
    padding: 0 0.25em;
    background-color: var(--backgroundDarkGray);
    color: var(--lightGrayText);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  
  #nav {
    padding: 15px;
    height: 100%;
  }
  
  .nav_btn {
    font-family: 'Amatic SC', cursive;
    font-size: x-large;
    line-height: 87%;
    text-align: center;
    padding: 5px;
    margin-left: 1px;
    margin-right: 1px;
    color: white;
  }
  
  #me_nav_btn {
    border: 0;
    
  }
  
  #projects_nav_btn {
    border: 0;
    height: 100%;
  }
  
  #find_me_nav_btn {
    border: 0;
    height: 100%;
  }
  
  #contact_nav_btn {
    border: 0;
    height: 100%;
  }
  
  #nav_wrap {
    height: 100%;
  }
  
  /*//////////////////////////////////
       FOOTER CSS
  //////////////////////////////////*/
  footer {
    width: 100%;
    padding: 0.25em;
    background-color: var(--backgroundDarkGray);
    color: var(--lightGrayText);
    text-align: center;
    position: fixed;
    bottom: 0;
  }
  
  /*//////////////////////////////////
       POPUP CSS
  //////////////////////////////////*/
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 800px;
    background-color: var(--lightGrayText);
    text-align: center;
  }
  
  .popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  
    font-size: 20px;
    font-family: inherit;
    padding: 5px 12px;
  
    margin: 0;
    padding: 0;
    border-width: 0;
    border-color: transparent;
    background: transparent;
    font-weight: 400;
    cursor: pointer;
    font-size: 20px;
    font-family: inherit;
    padding: 5px 12px;
    overflow: hidden;
    border-width: 0;
    border-radius: 4px;
    background: rgba(255,255,255,0.4);
    color: #000;
    -webkit-transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
    transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
  }
  
  .popup-inner .close-btn:hover {
    opacity: 1.75;
  }
  
  /*//////////////////////////////////
       BLACKJACK CSS
  //////////////////////////////////*/
  .game {
    width: 100%;
    /*height: 100%;*/
    font-family: inherit;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  
  .game_score {
    position: absolute;
    margin-left: 10px;
    font-size: 30px;
  }
  
  .game_controls {
    padding: 5px;
    font-family: inherit;
  }
  
  .control_btn {
    padding: 5px;
    font-family: inherit;
    font-size: 20px;
  }
  
  .divider {
    width: 5px;
    height: auto;
    display: inline-block;
  }
  
  .dealer_cards {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-bottom: 30px;
  }
  
  .player_cards {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  
  #dlr_crd_img_1, #dlr_crd_img_2, #dlr_crd_img_3, 
    #dlr_crd_img_4, #dlr_crd_img_5 {
    margin: 2px;
    width: 100px;
    height: auto;
  }
  
  #plyr_crd_img_1, #plyr_crd_img_2, #plyr_crd_img_3
    #plyr_crd_img_4, #plyr_crd_img_5 {
    margin: 2px;
    width: 100px;
    height: auto;
  }
  
  .game_controls {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 3;
  }
}









/*//////////////////////////////////
    MOBILE CSS MQ: 480px and down 
//////////////////////////////////*/

@media screen and (max-width: 480px) {
  /*//////////////////////////////////
       MAIN CSS
  //////////////////////////////////*/
  :root {
    --backgroundDarkGray: #2b2b2b;
    --lightGrayText: #bdbdbd;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    font-size: 22px;
    font-family: 'Amatic SC', cursive;
  }
  
  body {
    min-height: 100vh;
    background-color: #bdbdbd;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    background-color: var(--lightGrayText);
  }
  
  /*//////////////////////////////////
       HEADER CSS
  //////////////////////////////////*/
  header {
    width: 100%;
    padding: 0 0.25em;
    background-color: var(--backgroundDarkGray);
    color: var(--lightGrayText);
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  
  #nav {
    padding: 15px;
    height: 100%;
  }
  
  .nav_btn {
    font-family: 'Amatic SC', cursive;
    font-size: x-large;
    line-height: 87%;
    text-align: center;
    padding: 5px;
    margin-left: 1px;
    margin-right: 1px;
    color: white;
  }
  
  #me_nav_btn {
    border: 0;
    
  }
  
  #projects_nav_btn {
    border: 0;
    height: 100%;
  }
  
  #find_me_nav_btn {
    border: 0;
    height: 100%;
  }
  
  #contact_nav_btn {
    border: 0;
    height: 100%;
  }
  
  #nav_wrap {
    height: 100%;
  }
  
  /*//////////////////////////////////
       FOOTER CSS
  //////////////////////////////////*/
  footer {
    width: 100%;
    padding: 0.25em;
    background-color: var(--backgroundDarkGray);
    color: var(--lightGrayText);
    text-align: center;
    position: fixed;
    bottom: 0;
  }
  
  /*//////////////////////////////////
       POPUP CSS
  //////////////////////////////////*/
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 800px;
    background-color: var(--lightGrayText);
    text-align: center;
  }
  
  .popup-inner .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  
    font-size: 20px;
    font-family: inherit;
    padding: 5px 12px;
  
    margin: 0;
    padding: 0;
    border-width: 0;
    border-color: transparent;
    background: transparent;
    font-weight: 400;
    cursor: pointer;
    font-size: 20px;
    font-family: inherit;
    padding: 5px 12px;
    overflow: hidden;
    border-width: 0;
    border-radius: 4px;
    background: rgba(255,255,255,0.4);
    color: #000;
    -webkit-transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
    transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
  }
  
  .popup-inner .close-btn:hover {
    opacity: 1.75;
  }
  
  /*//////////////////////////////////
       BLACKJACK CSS
  //////////////////////////////////*/
  .game {
    width: 100%;
    /*height: 100%;*/
    font-family: inherit;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
  }
  
  .game_score {
    position: absolute;
    margin-left: 10px;
    font-size: 30px;
  }
  
  .game_controls {
    /*position: absolute;*/
    padding: 5px;
    font-family: inherit;
  }
  
  .control_btn {
    padding: 5px;
    font-family: inherit;
    font-size: 20px;
  }
  
  .divider {
    width: 5px;
    height: auto;
    display: inline-block;
  }
  
  .dealer_cards {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 1;
    margin-bottom: 30px;
  }
  
  .player_cards {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 2;
  }
  
  #dlr_crd_img_1, #dlr_crd_img_2, #dlr_crd_img_3, 
    #dlr_crd_img_4, #dlr_crd_img_5 {
    margin: 2px;
    width: 85px;
    height: auto;
  }
  
  #plyr_crd_img_1, #plyr_crd_img_2, #plyr_crd_img_3
    #plyr_crd_img_4, #plyr_crd_img_5 {
    margin: 2px;
    width: 85px;
    height: auto;
  }
  
  .game_controls {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 3;
    text-align: center;
  }
}

